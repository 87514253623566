import React from 'react';
import cx from 'classnames'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../../components/title"

const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			textAlign: "center"
		}
	},

	title: {
		fontSize: "2.5rem",
	},

	teamName: {
		fontSize: "1.8rem",
		marginBottom: theme.spacing(3),
	},
	teamDetails: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(7),
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
		}
	},
	featuredWrapper: {
		marginBottom: theme.spacing(12),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(0),
		}
	},
	featuredDetails: {
		padding: theme.spacing(12),
		paddingLeft: theme.spacing(14),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(6),
		},
	},
	featuredImage: {
		top: theme.spacing(4),
		width: `calc(100% + ${theme.spacing(6)}px)`,
		position: "relative",
		[theme.breakpoints.down('md')]: {
			top: "unset",
			width: "unset",
		}
	},
	featuredContainer: {
		position: 'relative',
		marginTop: theme.spacing(5),
		[theme.breakpoints.down('md')]: {
			marginTop: "unset"
		}
	},
}))


const FitAndRideTeam = ({ id, teamName, teamTitle, teamDescription, teamFeatured, teamImage, isReversed }) => {
	const classes = useStyles()

	if (teamFeatured) {
		return (
			<Grid item xs={12} className={classes.featuredWrapper} key={id}>
				<Grid style={isReversed ? { flexDirection: 'row-reverse' } : {}} container item>
					<Grid item item sm={6} xs={12} className={classes.featuredContainer}>
						{!isReversed && <Title className={classes.title} >{teamTitle}</Title>}
						<GatsbyImage
							alt="Out Team"
							image={getImage(teamImage)}
							className={classes.featuredImage}
						/>
					</Grid>
					<Grid container item sm={6} xs={12}>
						<Box p={10} className={cx(classes.teamDetails, classes.featuredDetails)}>
							<Title className={classes.teamName}>{teamName}</Title>
							<Typography>{teamDescription}</Typography>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		)
	}

	return (
		<Grid item sm={6} xs={12} className={classes.wrapper} key={id}>
			<GatsbyImage
				alt="Out Team"
				image={getImage(teamImage)}
				className={classes.image}
			/>
			<Box className={classes.teamDetails}>
				<Title className={classes.teamName}>{teamName}</Title>
				<Typography>{teamDescription}</Typography>
			</Box>
		</Grid>
	)
}

export default FitAndRideTeam
