import React from 'react';

import Layout from '../../components/layout';
import FitAndRideView from '../../views/fitAndRideView'

const FitAndRidePage = () => (
	<Layout>
		<FitAndRideView />
	</Layout>
)

export default FitAndRidePage;