import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../../components/title"
import Button from "../../components/button"

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  service: {
		marginTop: spacing(10),
		marginBottom: spacing(10),
	},
  contentContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: "flex-end",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: spacing(16),
      backgroundColor: palette.primary.main,
      width: spacing(4),
    }
  },
  content: {
    width: `calc(100% - ${spacing(4)}px)`,
    padding: spacing(4),
    backgroundColor: palette.secondary.main,
  },
  link: {
    textDecoration: 'none'
  },
  title: {
    fontSize: "2.3rem",
  },
  image: {
    [breakpoints.down('sm')]: {
      width: `calc(100% - ${spacing(4)}px)`,
      display: "flex",
      marginLeft: "auto",
    },
  }
}))

const FitAndRideService = ({ title, description, image, button }) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.service}>
      <Grid item md={6} xs={12} className={classes.contentContainer}>
        <Box className={classes.content}>
          <Title className={classes.title}>{title}</Title>
          <Typography variant="body2">{description}</Typography>
          <Box display="flex" justifyContent="center" mt={1.5}>
            <Link to="/kontakt" className={classes.link}>
              <Button>{button}</Button>
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} xs={12} >
        <GatsbyImage image={getImage(image)} alt={title} className={classes.image}/>
      </Grid>
    </Grid>
  )
}

export default FitAndRideService
