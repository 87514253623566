import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Title from "../../components/title"

import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "../../components/hero"
import Logo from '../../components/logo';
import FitAndRideTeam from './FitAndRideTeam';
import FitAndRideService from './FitAndRideService';

const useStyles = makeStyles(theme => ({
	fitandride: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		padding: theme.spacing(10),
		color: theme.palette.common.white,
		backgroundColor: theme.palette.error.main,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
		}
	},

	heroTitle: {
		'& section > h1': {
			[theme.breakpoints.down('lg')]: {
				fontSize: '150px',
			},
			'@media(max-width: 865px)': {
				fontSize: '90px ',
			},
			'@media(max-width: 576px)': {
				fontSize: '70px',
			},
		},
	},

	title: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem',
		}
	},

	additionalTitle: {
		marginBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem',
		}
	},

	logo: {
		width: "100%",
		height: "100%",
	},
	teamName: {
		fontSize: "1.8rem",
		marginBottom: theme.spacing(3),
	},
	teamDetails: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(7),
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
		}
	},

	additionTeam: {
		marginBottom: '50px',
	},

	additionTeamText: {
		padding: '0 10px',
		textAlign: 'center',
		backgroundColor: '#E1E1E1',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
}))

const FitAndRideViewPage = () => {
	const classes = useStyles()

	const { datoCmsPageFitandride } = useStaticQuery(graphql`
		query QueryViewFitandride {
			datoCmsPageFitandride {
				heroTitle
				heroImage {
					gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
				}
				fitandrideTitle
				fitandrideDescription
				serviceButton
				serviceDescription
				serviceTitle
				serviceImage {
					gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
				}
				teamTitle
				teamTitleAddition
				team {
					id
					teamName
					teamDescription
					teamFeatured
					teamImage {
						gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
				teamAddition{
					id
					teamName
					teamDescription
					teamFeatured
					teamImage {
						gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
			}
		}
  `)

	const {
		heroTitle,
		heroImage,
		fitandrideTitle,
		fitandrideDescription,
		serviceButton,
		serviceDescription,
		serviceTitle,
		serviceImage,
		teamTitle,
		teamTitleAddition,
		team = [],
		teamAddition,
	} = datoCmsPageFitandride || {}

	return (
		<React.Fragment>
			<Box className={classes.heroTitle}>
				<Hero full image={heroImage} title={heroTitle} />
			</Box>
			<Container maxWidth="lg">
				<Grid container spacing={6} className={classes.fitandride}>
					<Grid item md={7} sm={6} xs={12} className={classes.fitandrideContent}>
						<Typography className={classes.title} gutterBottom variant="h3">{fitandrideTitle}</Typography>
						<Typography>{fitandrideDescription}</Typography>
					</Grid>
					<Grid item md={5} sm={6} xs={12}>
						<Logo className={classes.logo} primary="#fff" secondary="#fff" />
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					{team.map((props, idx) => <FitAndRideTeam isReversed={idx == 1 ? true : false} key={idx} teamTitle={teamTitle} {...props} />)}
				</Grid>
				<FitAndRideService
					title={serviceTitle}
					image={serviceImage}
					button={serviceButton}
					description={serviceDescription}
				/>
				<Box className={classes.additionTeam}>
					{teamAddition.map(member => (
						<>
							<Title className={classes.additionalTitle}>{teamTitleAddition}</Title>
							<Grid container className={classes.additionTeamFlex}>
								<Grid item item sm={6} xs={12} className={classes.additionTeamContainer}>
									<GatsbyImage
										alt="Out Team"
										image={getImage(member.teamImage)}
										className={classes.featuredImage}
									/>
								</Grid>
								<Grid container item sm={6} xs={12}>
									<Box p={10} className={classes.additionTeamText}>
										<Box><Title className={classes.teamName}>{member.teamName}</Title></Box>
										<Typography>{member.teamDescription}</Typography>
									</Box>
								</Grid>
							</Grid>
						</>
					))}
				</Box>
				{/*<Box className={classes.additionTeam}>
					<FitAndRideTeam teamTitle={teamTitleAddition} {...teamAddition[0]} />
				</Box>*/}
			</Container>
		</React.Fragment >
	)
}

export default FitAndRideViewPage;